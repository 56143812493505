import isArray from 'lodash/isArray';
import find from 'lodash/find';

import { mapActions, mapGetters } from 'vuex';
import { nameSpace as paymentMethodNameSpace } from '@/vue/stores/CompanyAdminPaymentMethod/CompanyAdminPaymentMethodStore';

export default {
  computed: {
    ...mapGetters(paymentMethodNameSpace, {
      companyAdminPaymentMethodCollection: 'getCompanyAdminPaymentMethodCollection',
      getCompanyAdminPaymentMethodByUUID: 'getCompanyAdminPaymentMethodByUUID',
      activeCompanyPaymentMethod: 'getActiveCompanyPaymentMethodByCollection',
    }),
    hasCompanyAnyPaymentMethod() {
      return isArray(this.companyAdminPaymentMethodCollection)
        && this.companyAdminPaymentMethodCollection.length;
    },

    hasCompanyAnyInternalPaymentMethod() {
      if (!this.hasCompanyAnyPaymentMethod) {
        return false;
      }

      return find(
        this.companyAdminPaymentMethodCollection,
        companyPaymentMethod => companyPaymentMethod.internal && companyPaymentMethod.active,
      );
    },
  },

  methods: {
    ...mapActions(paymentMethodNameSpace, ['setCompanyAdminPaymentMethodCollection']),
  },
};
