import get from 'lodash/get';
import Api from '@/vue/api/backoffice';
import { fetchUserAccount } from '@/stores/User/UserMapper';

import BookingTypeMixin from './BookingType';
import UserDataMixin from './UserData';
import ProfileMixin from './Profile';
import TariffMixin from './Tariff';
import UserInsuranceMixin from './UserInsurance';
import CompanyMixin from './Company';
import CompanyPaymentMethodMixin from './CompanyAdminPaymentMethod';
import CSOperatorMixin from './CSOperator';

export default {
  data() {
    return {
      mandatoryData: [],
    };
  },

  mixins: [
    BookingTypeMixin,
    ProfileMixin,
    TariffMixin,
    UserInsuranceMixin,
    CompanyMixin,
    CompanyPaymentMethodMixin,
    CSOperatorMixin,
    UserDataMixin,
  ],

  methods: {
    fetchUserAccount,
    setAuthenticatedUser(userDetailsResponse = {}) {
      this.setUserData(userDetailsResponse);

      const {
        booking_types: bookingTypes,
        profiles,
        tariff,
        insurance,
        company,
      } = userDetailsResponse;

      this.setBookingTypeCollection(bookingTypes);
      const bookingType = this.currentBookingType || this.bookingTypeCollection[0];
      this.setCurrentBookingType(bookingType);

      this.setProfileCollection(profiles);
      const profile = this.currentProfile || this.profileCollection[0];
      this.setCurrentProfile(profile);

      this.setCurrentTariff(tariff);
      this.setUserInsurance(insurance);
      this.setCompanyData(company);
    },

    fetchExtraUserData(userDetailsResponse) {
      if (userDetailsResponse.company) {
        Api.payments.listCompanyPaymentMethods(userDetailsResponse.company.uuid)
          .then(data => {
            this.setCompanyAdminPaymentMethodCollection(data.data);
          });

        const dedicatedCsOperatorUuid = get(userDetailsResponse, 'company.dedicated_fleet_cs_operator');
        if (dedicatedCsOperatorUuid) {
          this.fetchCSOperator(dedicatedCsOperatorUuid);
        }
      }

      this.fetchUserAccount({ userUuid: userDetailsResponse.uuid });
    },

    fetchAuthenticatedUser() {
      return Api.userCompany.getUserDetail();
    },
  },
};
